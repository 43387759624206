<div class="container-fluid pt-3 px-0 mb-3">
  <nav class="navbar navbar-expand-lg navbar-light pt-4">
    <div class="container">
      <a class="nav-link position-absolute start-0" (click)="goBack()">
        <i class="fa fa-arrow-left fa-2x" aria-hidden="true"></i>
      </a>
      <!-- Logo and Brand -->
      <a class="navbar-brand" href="#">
        <img src="assets/logos/pocketmartNavbar.png" alt="Logo" width="150" />
      </a>
      <!-- Login user icon -->
      <a class="nav-link position-absolute end-0" routerLink="/profile">
        <i class="userIcon fa fa-user-circle fa-2x" aria-hidden="true"></i>
      </a>
    </div>
  </nav>
  <hr class="mt-4 mb-0" />
  <br />
  <div class="container" *ngFor="let singleOrder of subscription">
    <div class="row">
      <div class="col-8" *ngIf="singleOrder.orderStatus !== 'DEL'">
        <label class="switch">
          <input
            type="checkbox"
            [(ngModel)]="isAssigned"
            (change)="toggleAssignment()"
          />
          <span class="slider round"></span> </label
        ><span>&nbsp; Accept delivery</span>
      </div>
      <div class="col" style="float: right">
        <h3
          [ngStyle]="{ color: getColor(singleOrder.type) }"
          style="float: right; margin-bottom: 0"
        >
          Lunch
          <!-- {{ mealMappings[singleOrder.type] }} -->
        </h3>
        <br />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p style="float: right; margin-bottom: 0">
          <b>{{ singleOrder.orderForDate | date : "dd/MM/yyyy" }}</b>
        </p>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <h3>{{ singleOrder.username }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <i>Delivery address</i>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p style="margin-bottom: 5px">
          <i class="fas fa-map-marker-alt" style="color: rgb(88, 133, 216)"></i>
          &nbsp;&nbsp;{{ singleOrder.address.address }}
        </p>

        <p
          style="margin-bottom: 5px"
          *ngIf="singleOrder.address.building"
          class="detailContent"
        >
          {{ singleOrder.address.building }}
        </p>
        <p
          style="margin-bottom: 5px"
          *ngIf="singleOrder.address.street"
          class="detailContent"
        >
          {{ singleOrder.address.street }}
        </p>
        <p
          style="margin-bottom: 5px"
          *ngIf="singleOrder.address.locality"
          class="detailContent"
        >
          {{ singleOrder.address.locality }}
        </p>

        <div class="row mb-2">
          <div class="col">
            <p style="margin-bottom: 5px; margin-top: 5px">
              <i
                class="fas fa-phone fa-flip-horizontal"
                style="color: green"
              ></i
              >&nbsp;&nbsp;
              <a style="color: blue" [href]="'tel:' + buyerPhoneNumber">
                +91 {{ buyerPhoneNumber }}</a
              >
            </p>
          </div>
          <div class="col">
            <p
              (click)="openGoogleMapsDirections()"
              style="
                margin-bottom: 5px;
                margin-top: 5px;
                margin-left: 20px;
                color: red;
                cursor: pointer;
              "
            >
              <i class="fa-solid fa-route" style="color: rgb(88, 133, 216)"></i>
              Show on map
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <p style="color: grey">
              Registered user contact : {{ singleOrder.mobile }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <hr style="margin-top: 0" />
    <div class="row">
      <div class="col-5">
        <p style="margin-bottom: 0">Order Status</p>
      </div>
      <div class="col mb-2 ps-0">
        <p
          class="statusColour"
          [style.background-color]="
            getStatusBackgroundColor(singleOrder.orderStatus)
          "
        >
          {{
            singleOrder.orderStatus === "PEN"
              ? "Pending"
              : singleOrder.orderStatus === "ACC"
              ? "Accepted"
              : singleOrder.orderStatus === "REJ"
              ? "Rejected"
              : singleOrder.orderStatus === "OFD"
              ? "Out for delivery"
              : singleOrder.orderStatus === "DEL"
              ? "Delivered"
              : singleOrder.orderStatus === "CAN"
              ? "Cancelled"
              : singleOrder.orderStatus === "KCKCAN"
              ? "Delivery Cancelled"
              : ""
          }}
        </p>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-5 pe-0">
        <p style="margin-bottom: 0">Payment status</p>
      </div>
      <div class="col mb-2 ps-0">
        <p
          class="statusColour"
          [style.background-color]="
            getPaymentStatusBackgroundColor(singleOrder.paymentStatus)
          "
        >
          {{
            singleOrder.paymentStatus === "NPD"
              ? "Not Paid"
              : singleOrder.paymentStatus === "PEN"
              ? "Pending"
              : singleOrder.paymentStatus === "PAID"
              ? "Paid"
              : singleOrder.paymentStatus === "CON"
              ? "Payment Confirmed"
              : singleOrder.paymentStatus === "FAIL"
              ? "Payment failed"
              : ""
          }}
        </p>
      </div>
    </div> -->

    <!-- <div class="row" *ngIf="singleOrder.paymentMethod !== 'TBD'">
      <div class="col-5 pe-0">
        <p>Payment Method</p>
      </div>
      <div class="col mb-2 ps-0">
        <p
          class="statusColour"
          [style.background-color]="
            getPaymentMethodBackgroundColor(singleOrder.paymentMethod)
          "
        >
          {{
            singleOrder.paymentMethod === "COD"
              ? "Cash on Delivery"
              : singleOrder.paymentMethod === "UPI"
              ? "UPI"
              : singleOrder.paymentMethod === "TBD"
              ? "Not Selected"
              : ""
          }}
        </p>
      </div>
    </div> -->

    <div class="row orderID">
      <div class="col">
        <p style="color: grey">
          Subscription ID&nbsp;:&nbsp; <b>{{ singleOrder.subscriptionID }}</b>
        </p>
        <p style="color: grey">
          Kitchen Name&nbsp;:&nbsp; <b>{{ kitchenName }}</b>
        </p>
        <p style="color: grey">
          Kitchen Phone&nbsp;:&nbsp; <b>{{ kitchenMobileNumber }}</b>
        </p>
      </div>

      <div class="row p-0"></div>

      <br /><br />
    </div>

    
    <div class="row mt-3" *ngIf="singleOrder.orderStatus === 'OFD'&&singleOrder.assignedAgent!==null">
      <div class="col">
        <button
          style="width: 100%"
          type="button"
          class="btn btn-outline-danger cancelButton"
          (click)="subscriptionDelivered()"
        >
          Delivered
        </button>
      </div>
    </div>
  </div>
</div>
