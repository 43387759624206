import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { OrderService } from './orders.service';

const BACKEND_URL_LUNCH_BELL = environment.apiUrlLunchBell;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent implements OnInit {
  phoneNumber: any;
  loading: boolean = false;

  // location details
  latitude: any;
  longitude: any;

  ngOnInit(): void {
    this.phoneNumber = localStorage.getItem('phoneNumber');
    this.updateLocation();
    // Other initialization remains the same
    const storedNearestOrders = this.orderService.getNearestOrders(); // Get stored nearest orders
    const storedOrders = this.orderService.getOrders();
    if (storedNearestOrders && storedNearestOrders.length > 0) {
      this.orders = storedNearestOrders; // If nearest orders exist, use them
    } else if (storedOrders && storedOrders.length > 0) {
      this.orders = storedOrders;
    } else {
      this.fetchOrders(); // Otherwise, fetch orders from the server
    }
  }

  orderStatusSwitch(orderStatus: String): any {
    switch (orderStatus) {
      case 'OFD':
        return 'Out for Delivery';
      case 'DEL':
        return 'Delivered';
      case 'ACC':
        return 'Accepted';
    }
  }

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private router: Router,
    private orderService: OrderService
  ) {}

  orders: any = [];
  formatDate(date: string): string {
    const formattedDate = new Date(date);
    return this.datePipe.transform(formattedDate, 'dd/MM/yyyy') || '';
  }
  fetchOrders(): void {
    // Set loading to true before making the HTTP request
    this.loading = true;
    this.http
      .get<any>(`${BACKEND_URL_LUNCH_BELL}/orders/${this.phoneNumber}`)
      .subscribe(
        (response) => {
          this.orders = response.orders;
          this.orderService.setOrders(this.orders);
          console.log(this.orders);
          // Set loading to false after receiving the response
          this.loading = false;
        },
        (error) => {
          console.error('Error fetching orders:', error);
          // Ensure loading is set to false even if an error occurs
          this.loading = false;
        }
      );
  }

  getNearestOrders(): void {
    // Check if latitude and longitude are provided
    if (!this.latitude || !this.longitude) {
      console.error('Latitude and longitude are required.');
      return;
    }

    // Make the HTTP request to fetch nearest orders
    this.http
      .get<any>(
        `${BACKEND_URL_LUNCH_BELL}/nearestOrders/${this.phoneNumber}?latitude=${this.latitude}&longitude=${this.longitude}`
      )
      .subscribe(
        (response) => {
          this.orders = response.orders;
          this.orderService.setNearestOrders(this.orders);
          console.log(this.orders);
        },
        (error) => {
          console.error('Error fetching nearest orders:', error);
        }
      );
  }

  showConfirmationDialog(): void {
    Swal.fire({
      title: 'Refresh order listing',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Filter nearest orders',
      cancelButtonText: 'Refresh orders',
    }).then((result) => {
      if (result.isConfirmed) {
        // Handle filter based on nearest option
        // Check for location permission
        if (navigator.geolocation) {
          navigator.permissions
            .query({ name: 'geolocation' })
            .then((permissionStatus) => {
              if (permissionStatus.state === 'granted') {
                // Location permission granted, proceed to filter nearest orders
                // You can implement your logic here
                // location.reload();
                this.getNearestOrders();
              } else {
                // Location permission denied, show Swal to enable location
                Swal.fire({
                  icon: 'error',
                  title: 'Permission Denied',
                  text: 'Please enable location services to filter nearest orders.',
                });
              }
            });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle refresh option
        console.log('Before reloading location');
        location.reload();
        console.log('After reloading location');
      }
    });
  }

  updateLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          console.log(latitude, longitude);
          // Set location details in local storage
          localStorage.setItem('latitude', latitude.toString());
          localStorage.setItem('longitude', longitude.toString());
          this.latitude = localStorage.getItem('latitude');
          this.longitude = localStorage.getItem('longitude');
          // this.fetchOrders();

          // Send location data to backend
          // this.sendLocationToBackend(latitude, longitude);
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            Swal.fire({
              icon: 'error',
              title: 'Permission Denied',
              text: 'Please enable location services to update your location.',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Error retrieving your location.',
            });
          }
        }
      );
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Unsupported Browser',
        text: 'Geolocation is not supported by this browser.',
      });
    }
  }

  orderDescription(order: any) {
    if (order.orderID) {
      this.goToOrderDescription(order);
    }
    if (order.subscriptionID) {
      this.goToSubscriptionDescription(order);
    }
  }

  goToOrderDescription(order: any): void {
    const loggedInPhoneNumber = localStorage.getItem('phoneNumber');

    if (order.assignedAgent === null) {
      // Handle the case where the order is not assigned to any agent
      // Navigate to the order description page
      this.router.navigate(['/order-description'], {
        queryParams: { orderID: order.orderID },
      });
    } else if (order.assignedAgent !== loggedInPhoneNumber) {
      // Display Swal indicating that the order is already assigned
      Swal.fire({
        icon: 'error',
        title: 'Order Already Assigned',
        text: 'This order is already assigned to another agent.',
      });
    } else {
      // Navigate to the order description page
      this.router.navigate(['/order-description'], {
        queryParams: { orderID: order.orderID },
      });
    }
  }

  goToSubscriptionDescription(subscription: any): void {
    const loggedInPhoneNumber = localStorage.getItem('phoneNumber');
    console.log(
      subscription._id,
      'sadihsauidhsaiuhdiosajoidjsaodjsaodkopasdkjosajopdjsaod'
    );

    if (subscription.assignedAgent === null) {
      // Handle the case where the subscription is not assigned to any agent
      // Navigate to the subscription description page
      this.router.navigate(['/subscription-description'], {
        queryParams: {
          subscriptionDBID: subscription._id,
        },
      });
    } else if (!subscription.assignedAgent) {
      this.router.navigate(['/subscription-description'], {
        queryParams: {
          subscriptionDBID: subscription._id,
        },
      });
    } else if (subscription.assignedAgent !== loggedInPhoneNumber) {
      // Display Swal indicating that the subscription is already assigned
      Swal.fire({
        icon: 'error',
        title: 'Subscription Already Assigned',
        text: 'This subscription is already assigned to another agent.',
      });
    } else {
      // Navigate to the subscription description page
      this.router.navigate(['/subscription-description'], {
        queryParams: {
          subscriptionDBID: subscription._id,
        },
      });
    }
  }
}
