<div class="container-fluid pt-3 px-0">
<nav class="navbar navbar-expand-lg navbar-light pt-4">
  <div class="container">
    <a class="nav-link position-absolute start-0" (click)="goBack()">
      <i class="fa fa-arrow-left fa-2x" aria-hidden="true"></i>
    </a>
    <!-- Logo and Brand -->
    <a class="navbar-brand" href="#">
      <img src="assets/logos/pocketmartNavbar.png" alt="Logo" width="150">

    </a>
<!-- Login user icon -->
<a class="nav-link position-absolute end-0" routerLink="/home">
  <i class="homeIcon fa fa-home fa-2x" aria-hidden="true"></i>
</a>

  </div>
</nav>
<hr class="mt-4 mb-0">
<br>
<!-- Content Area -->

<div class="container-fluid p-0">
  <div class="container">
    <div class="row">
      <h3 class="pt-0 pb-1 orders">Profile Details</h3>
    </div><br>
    <div class="row pb-2">
      <div class="col-md-6 offset-md-3">
        <div class="card" style="border-radius: 20px;">
          <div class="card-body">
            <!-- User icon circle -->
            <div class="user-icon-circle mb-3 d-flex justify-content-center align-items-center"> <!-- Centering user icon -->
              <!-- You can style this div to create a circular shape for the user icon -->
              <i class="fa fa-user-circle fa-5x" style="color: gray;"></i>
            </div>
            <!-- Username -->
            <div class="row mb-3">
              <div class="col text-left">
                <h5 class="card-title mb-0">Username:</h5>
                <p class="card-text">{{user&&user.username}}</p>
              </div>
            </div>
            <!-- Phone number -->
            <div class="row mb-3">
              <div class="col text-left">
                <h5 class="card-title mb-0">Phone:</h5>
                <p class="card-text">{{user&&user.phone}}</p>
              </div>
            </div>
            <!-- Address -->
            <div class="row mb-3">
              <div class="col text-left">
                <h5 class="card-title mb-0">Delivery Location:</h5>
                <p class="card-text"><ng-container *ngFor="let location of user?.assignedLocations">
                  <p style="margin: 2px;">{{ location }}</p>
                </ng-container></p>
              </div>
            </div>
             <!-- Delivered Orders -->
             <div class="row mb-3">
              <div class="col text-left">
                <h6 class="card-title mb-0" style="color: red; text-decoration: underline; cursor: pointer;" routerLink="/delivered">Delivered orders</h6>
              </div>
            </div>
            <br><br><br>
            <!-- Logout button -->
            <div class="row">
              <div class="col text-center"> <!-- Centering logout button -->
                <button type="button" class="btn btn-danger" (click)="logout()">Logout</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



</div>
