import { Component } from '@angular/core';
import { Router } from '@angular/router'; // Import Router
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';

import { environment } from '../../../environments/environment';
const BACKEND_URL_LUNCH_BELL = environment.apiUrlLunchBell;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent {
  constructor(private router: Router, private http: HttpClient) {} // Inject Router
  ngOnInit(): void {}

  phoneNumber: any;
  keyword: any;
  errorMessage: any;

  login(): void {
    // Send login credentials to backend for verification
    this.http
      .post<any>(`${BACKEND_URL_LUNCH_BELL}/login`, {
        phoneNumber: this.phoneNumber,
        keyword: this.keyword,
      })
      .subscribe(
        (response) => {
          if (response.success) {
            console.log(response);
            localStorage.setItem('token', response.token);
            localStorage.setItem('phoneNumber', response.phoneNumber);
            // Redirect to /home route if login is successful
            this.router.navigate(['/home']);
          } else {
            this.errorMessage = 'Invalid phone number or keyword';
          }
        },
        (error) => {
          console.error('Error logging in:', error);
          if (error.status === 401) {
            // Show SweetAlert for invalid password
            Swal.fire({
              icon: 'error',
              title: 'Invalid Keyword',
              text: 'Please check your phone number and keyword and try again',
            });
          } else {
            this.errorMessage = 'An error occurred while logging in';
            Swal.fire({
              icon: 'error',
              title: 'An error occured',
              text: 'An error occured please try again',
            });
          }
        }
      );
  }
}
