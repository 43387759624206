import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
const BACKEND_URL_LUNCH_BELL = environment.apiUrlLunchBell;

@Component({
  selector: 'app-delivered',
  templateUrl: './delivered.component.html',
  styleUrl: './delivered.component.css',
})
export class DeliveredComponent implements OnInit {
  ngOnInit(): void {
    this.phoneNumber = localStorage.getItem('phoneNumber');
    this.fetchDeliveredOrders();
  }
  loading: boolean = false;
  phoneNumber: any;

  constructor(
    public location: Location,
    private http: HttpClient,
    private datePipe: DatePipe,
    private router: Router
  ) {}
  deliveredOrders: any = [];
  goBack(): void {
    this.location.back();
  }
  formatDate(date: string): string {
    const formattedDate = new Date(date);
    return this.datePipe.transform(formattedDate, 'dd/MM/yyyy') || '';
  }
  orderStatusSwitch(orderStatus: String): any {
    switch (orderStatus) {
      case 'OFD':
        return 'Out for Delivery';
      case 'DEL':
        return 'Delivered';
    }
  }
  fetchDeliveredOrders(): void {
    // Set loading to true before making the HTTP request
    this.loading = true;
    this.http
      .get<any>(
        `${BACKEND_URL_LUNCH_BELL}/delivered-orders/${this.phoneNumber}`
      )
      .subscribe(
        (response) => {
          this.deliveredOrders = response.deliveredOrders;
          console.log(this.deliveredOrders);
          // Set loading to false after receiving the response
          this.loading = false;
        },
        (error) => {
          console.error('Error fetching delivered orders:', error);
          // Ensure loading is set to false even if an error occurs
          this.loading = false;
        }
      );
  }

  orderDescription(order: any) {
    if (order.orderID) {
      this.goToOrderDescription(order);
    }
    if (order.subscriptionID) {
      this.goToSubscriptionDescription(order);
    }
  }

  goToOrderDescription(order: any): void {
    this.router.navigate(['/order-description'], {
      queryParams: { orderID: order.orderID },
    });
  }

  goToSubscriptionDescription(subscription: any): void {
    this.router.navigate(['/subscription-description'], {
      queryParams: {
        subscriptionDBID: subscription._id,
      },
    });
  }
}
