<div class="container-fluid pt-3 px-0">
  <nav class="navbar navbar-expand-lg navbar-light pt-4">
    <div class="container">
      <a class="nav-link position-absolute start-0" (click)="goBack()">
        <i class="fa fa-arrow-left fa-2x" aria-hidden="true"></i>
      </a>
      <!-- Logo and Brand -->
      <a class="navbar-brand" href="#">
        <img src="assets/logos/pocketmartNavbar.png" alt="Logo" width="150" />
      </a>
      <!-- Login user icon -->
      <a class="nav-link position-absolute end-0" routerLink="/home">
        <i class="homeIcon fa fa-home fa-2x" aria-hidden="true"></i>
      </a>
    </div>
  </nav>
  <hr class="mt-4 mb-0" />
  <br />
  <!-- Content Area -->
  <div class="container-fluid p-0">
    <div class="container">
      <div class="row">
        <h3 class="pt-0 pb-1 orders">Delivered orders</h3>
      </div>

      <div *ngIf="loading" class="loading-spinner">
        <div class="spinner"></div>
        <p>Loading...</p>
      </div>

      <div
        class="row pb-2"
        *ngFor="let delivered of deliveredOrders"
          (click)="orderDescription(delivered)"
      >
        <div class="col p-0" *ngIf="delivered.subscriptionID">
          <div class="card small-card">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title">Lunch</h5>
                </div>
                <div class="col text-end">
                  <span class="badge bg-success"> Subscription </span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p>Date: {{ formatDate(delivered.orderForDate) }}</p>
                </div>
              </div>
              <hr class="m-0 p-1" />

              <div class="row">
                <div class="col">
                  <p class="p-0 m-0 mb-1">Order Status:</p>
                </div>
                <div class="col">
                  <p class="p-0 m-0 mb-0" style="color: green">
                    {{ orderStatusSwitch(delivered.orderStatus) }}
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <p class="p-0 m-0 mb-1" style="font-size: small">
                    Subscription ID: {{ delivered.subscriptionID }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col p-0" *ngIf="delivered.orderID">
          <div class="card small-card">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title">Lunch</h5>
                </div>
                <div class="col text-end">
                  <h5 class="card-title">&#8377; {{ delivered.totalPrice }}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p>Date: {{ formatDate(delivered.orderForDate) }}</p>
                </div>
              </div>
              <hr class="m-0 p-1" />

              <div class="row">
                <div class="col">
                  <p class="p-0 m-0 mb-1">Order Status:</p>
                </div>
                <div class="col">
                  <p class="p-0 m-0 mb-0" style="color: green">
                    {{ orderStatusSwitch(delivered.orderStatus) }}
                  </p>
                </div>
              </div>
              <!-- <div class="row">
                <div class="col">
                  <p class="p-0 m-0 mb-1">Payment method:</p>
                </div>
                <div class="col">
                  <p class="p-0 m-0 mb-1">
                    <span style="font-weight: 500; color: rgb(182, 47, 47)">{{
                      delivered.paymentMethod
                    }}</span>
                  </p>
                </div>
              </div> -->
              <div class="row">
                <div class="col">
                  <p class="p-0 m-0 mb-1" style="font-size: small">
                    Order ID: {{ delivered.orderID }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- for subscription order listing -->
      <div
        class="row pb-2"
        *ngFor="let delivered of deliveredOrders"
        [routerLink]="['/subscription-description']"
        [queryParams]="{
          subscriptionDBID: delivered.subscriptionDetails && delivered._id
        }"
      >
        <!-- <div class="col p-0" *ngIf="delivered.subscriptionID">
          <div class="card small-card">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title">Lunch</h5>
                </div>
                <div class="col text-end">
                  <span class="badge bg-success"> Subscription </span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p>Date: {{ formatDate(delivered.orderForDate) }}</p>
                </div>
              </div>
              <hr class="m-0 p-1" />

              <div class="row">
                <div class="col">
                  <p class="p-0 m-0 mb-1">Order Status:</p>
                </div>
                <div class="col">
                  <p class="p-0 m-0 mb-0" style="color: green">
                    {{ orderStatusSwitch(delivered.orderStatus) }}
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <p class="p-0 m-0 mb-1" style="font-size: small">
                    Subscription ID: {{ delivered.subscriptionID }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>

      <div
        class="container"
        *ngIf="deliveredOrders.length <= 0"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          height: 70vh;
        "
      >
        <div class="card noOrders">
          <div class="row p-3">
            <div class="col">
              <h3 class="noOderHeading" style="text-align: center">
                You don't have any deliveries yet.
              </h3>
              <hr class="m-0 p-0" />
            </div>
          </div>
          <div class="row p-2 pt-0">
            <div class="col">
              <p style="text-align: center; color: gray">
                Check again after some time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
