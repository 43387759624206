import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router'; //
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
const BACKEND_URL_LUNCH_BELL = environment.apiUrlLunchBell;
@Component({
  selector: 'app-subscription-description',
  templateUrl: './subscription-description.component.html',
  styleUrl: './subscription-description.component.css',
})
export class SubscriptionDescriptionComponent {
  isAssigned: boolean = false;
  toggleAssignment(): void {
    // Send API request to toggle assignment status
    this.http
      .put(
        `${BACKEND_URL_LUNCH_BELL}/subscription/${this.subscriptionDBID}/assign`,
        {
          assigned: this.isAssigned,
        }
      )
      .subscribe(
        (response) => {
          console.log('Assignment status updated successfully:', response);
          // Handle success response
          location.reload();
        },
        (error) => {
          console.error('Error updating assignment status:', error);
          // Handle error response
        }
      );
  }
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router // private appOpenService: AppOpenService, // private kcksubscriptionNotificationService: KcksubscriptionNotificationService
  ) {}
  subscriptionDBID: any;
  phoneNumber: any;
  buyerID: any;
  sellerID: any;
  subscription: any;
  totalPrice: any;
  enableSaveButton: boolean = false;
  buyerPhoneNumber: any;
  buyerWhatsApp: any;

  // location details
  currentLatitude: any;
  currentLongitude: any;

  // delivery lat and long
  latitude: any;
  longitude: any;

  serverTimeHour: any;

  // getServerTimeHour(): void {
  //   this.http.get<any>(`${BACKEND_URL_LUNCH_BELL}/serverTimeHourDate`).subscribe(
  //     (response) => {
  //       this.serverTimeHour = response.hour;
  //       console.log('Server time hour:', this.serverTimeHour);
  //       // Use the server time hour as needed
  //     },
  //     (error) => {
  //       console.error('Error fetching server time hour:', error);
  //     }
  //   );
  // }

  checkAssignedAgent(): void {
    const loggedInPhoneNumber = localStorage.getItem('phoneNumber');

    if (
      this.subscription[0].assignedAgent !== null &&
      this.subscription[0].assignedAgent !== loggedInPhoneNumber
    ) {
      // Display Swal indicating that the subscription is not assigned to the logged-in user
      Swal.fire({
        icon: 'error',
        title: 'Unauthorized Access',
        text: 'You are not authorized to view this subscription.',
        allowOutsideClick: false,
      }).then((result) => {
        // Navigate the user back to the previous page
        this.router.navigate(['/home']); // Update this with the appropriate route
      });
    }
    console.log('worked assigned agent check');
  }

  ngOnInit(): void {
    this.currentLatitude = localStorage.getItem('latitude');
    this.currentLongitude = localStorage.getItem('longitude');
    this.updateLocation();
    // this.getServerTimeHour();
    this.phoneNumber = localStorage.getItem('phoneNumber');
    this.route.queryParams.subscribe((params) => {
      this.subscriptionDBID = params['subscriptionDBID'];
      console.log('subscription db ID: ', this.subscriptionDBID);
    });
    this.fetchsubscriptionDetails();
  }

  mealMappings = {
    BRE: 'Breakfast',
    LUN: 'Lunch',
    DIN: 'Dinner',
  };

  getColor(type: string): string {
    switch (type) {
      case 'BRE':
        return 'black';
      case 'LUN':
        return 'black';
      case 'DIN':
        return 'black';

      default:
        return 'black';
    }
  }

  goBack(): void {
    this.location.back();
  }

  getStatusBackgroundColor(subscriptionStatus: string): string {
    switch (subscriptionStatus) {
      case 'PEN':
        return '#F1C27B';
      case 'ACC':
        return '#98D4BB';
      case 'REJ':
        return '#F3CBBD';
      case 'OFD':
        return '#C1D5DE';
      case 'DEL':
        return '#C1D5DE';
      case 'KCKCAN':
        return '#D9C2BD';
      case 'CAN':
        return '#D9C2BD';
      default:
        return 'white';
    }
  }

  getPaymentStatusBackgroundColor(paymentStatus: string): string {
    switch (paymentStatus) {
      case 'NPD':
        return '#B6D8F2';
      case 'PEN':
        return '#F1C27B';
      case 'PAID':
        return '#CCD4BF';
      case 'PAID':
        return '#DEEDE6';
      case 'FAIL':
        return 'red';
      default:
        return 'white';
    }
  }

  refresh() {
    console.log('the refresh button has been clicked');
    this.fetchsubscriptionDetails();
  }

  deliveryCharge: any;
  gstAmount: any;
  offerPrice: any;
  sellerProfileID: string =''
  fetchsubscriptionDetails() {
    const profileID = localStorage.getItem('profileID');
    // Make a GET request to fetch subscription details
    this.http
      .get(
        `${BACKEND_URL_LUNCH_BELL}/subscriptionDescription/${this.subscriptionDBID}/${this.phoneNumber}`
      )
      .subscribe(
        (response) => {
          console.log(response);
          this.subscription = response;
          console.log(
            'subscription details:',
            this.subscription,
            this.subscriptionDBID
            // this.sellerID,
            // this.buyerID
          );

          this.buyerPhoneNumber = this.subscription[0].address.deliveryNumber;

          this.buyerWhatsApp = this.subscription[0].whatsApp;
          this.gstAmount = this.subscription[0].gstPrice;
          this.offerPrice = this.subscription[0].offerPrice;
          this.isAssigned = this.subscription[0].assignedAgent !== null;
          this.longitude =
            this.subscription[0].address.location.coordinates &&
            this.subscription[0].address.location.coordinates[0];
          this.latitude =
            this.subscription[0].address.location.coordinates &&
            this.subscription[0].address.location.coordinates[1];
          console.log(this.latitude, this.longitude);

          this.sellerID = this.subscription[0].sellerProfileID;
          console.log("sellerProfileID: ",this.sellerID)
          this.getKitchenDetails(this.sellerID);
          this.checkAssignedAgent();

          console.log(
            'the phone number is :',
            this.buyerPhoneNumber,
            this.buyerWhatsApp
          );
        },
        (error) => {
          console.log('Error fetching subscription details:', error);
          // Handle error, show an error message, etc.
        }
      );
  }


  kitchenDetails: any
  kitchenName: string=''
  kitchenMobileNumber: string=''
  getKitchenDetails(sellerID: string): void {
    this.http.get<any>(`${BACKEND_URL_LUNCH_BELL}/getKitchenDetails/${sellerID}`).subscribe(
      (response) => {
        this.kitchenDetails = response;
        this.kitchenName= this.kitchenDetails.username;
        this.kitchenMobileNumber= this.kitchenDetails.mobile;
        console.log('Kitchen details:', this.kitchenDetails);
      },
      (error) => {
        console.error('Error fetching kitchen details:', error);
        // Handle error, show an error message, etc.
      }
    );
  }

  showOnMap() {
    window.location.href = 'https://maps.app.goo.gl/5rwMZLjhEkP6uK5M7';
  }

  updateLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          console.log(latitude, longitude);
          // Set location details in local storage
          localStorage.setItem('latitude', latitude.toString());
          localStorage.setItem('longitude', longitude.toString());
          this.currentLatitude = localStorage.getItem('latitude');
          this.currentLongitude = localStorage.getItem('longitude');
          // this.fetchsubscriptions();

          // Send location data to backend
          // this.sendLocationToBackend(latitude, longitude);
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            Swal.fire({
              icon: 'error',
              title: 'Permission Denied',
              text: 'Please enable location services to update your location.',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Error retrieving your location.',
            });
          }
        }
      );
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Unsupported Browser',
        text: 'Geolocation is not supported by this browser.',
      });
    }
  }

  openGoogleMapsDirections() {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then((permissionStatus) => {
          if (permissionStatus.state === 'denied') {
            Swal.fire({
              icon: 'error',
              title: 'Permission Denied',
              text: 'Please enable location services to use this feature.',
            });
          } else {
            // Construct the Google Maps URL with the destination coordinates.
            let googleMapsUrl: string;
            if (this.currentLatitude && this.currentLongitude) {
              if (this.latitude && this.longitude) {
                console.log(this.longitude, this.latitude);
                googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${this.latitude},${this.longitude}`;
                // Open the Google Maps URL in a new tab or window.
                if (typeof window !== 'undefined') {
                  window.open(googleMapsUrl, '_blank');
                }
              }
            }
          }
        });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Unsupported Browser',
        text: 'Geolocation is not supported by this browser.',
      });
    }
  }

  subscriptionDelivered() {
    const profileID = localStorage.getItem('profileID');
    // Display SweetAlert confirmation dialog
    Swal.fire({
      title: 'Confirm subscription Delivery',
      text: 'Are you sure you want to mark this subscription as delivered?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, mark as delivered',
      confirmButtonColor: '#218b82',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        // If the user confirms, proceed with the HTTP request
        this.http
          .put(
            BACKEND_URL_LUNCH_BELL +
              `/subscriptionOrderDelivered/${this.subscriptionDBID}/${this.phoneNumber}`,
            {}
          )
          .subscribe((response) => {
            console.log('Backend response:', response);
            this.fetchsubscriptionDetails();
            location.reload();
          });
      }
    });
  }

  paymentConfirmed() {
    const profileID = localStorage.getItem('profileID');
    // Display SweetAlert confirmation dialog
    Swal.fire({
      title: 'Confirm payment received',
      text: 'Are you sure you want to confirm the payment received for this subscription?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, confirm payment',
      confirmButtonColor: 'green', // Set confirm button color to green
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        // If the user confirms, proceed with the HTTP request
        this.http
          .put(
            BACKEND_URL_LUNCH_BELL +
              `/subscriptionsPaymentConfirmed/${this.subscriptionDBID}/${this.phoneNumber}`,
            {}
          )
          .subscribe((response) => {
            console.log('Backend response:', response);
            this.fetchsubscriptionDetails();
          });
      }
    });
  }

  getPaymentMethodBackgroundColor(paymentMethod: string): string {
    switch (paymentMethod) {
      case 'COD':
        return '#7dc98d';
      case 'UPI':
        return '#8369f5';
      case 'TBD':
        return '#DEEDE6';
      default:
        return 'white';
    }
  }
}
