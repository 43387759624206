<div class="container-fluid pt-3 px-0">
  <nav class="navbar navbar-expand-lg navbar-light pt-4">
    <div class="container">
      <!-- Refresh and filter button -->
      <button
        class="btn btn-outline-success me-2"
        (click)="showConfirmationDialog()"
        aria-label="Refresh"
      >
        <i class="fas fa-sync-alt"></i>
      </button>
      <!-- Logo and Brand -->
      <a class="navbar-brand" href="#">
        <img src="assets/logos/pocketmartNavbar.png" alt="Logo" width="150" />
      </a>
      <!-- Login user icon -->
      <a class="nav-link position-absolute end-0" routerLink="/profile">
        <i class="userIcon fa fa-user-circle fa-2x" aria-hidden="true"></i>
      </a>
    </div>
  </nav>
  <hr class="mt-4 mb-0" />
  <br />
  <!-- Content Area -->

  <div class="container-fluid p-0">
    <div class="container">
      <div class="row">
        <h3 class="pt-0 pb-1 orders" style="color: rgb(6, 128, 0)">
          'Out for delivery' orders
        </h3>
      </div>

      <div *ngIf="loading" class="loading-spinner">
        <div class="spinner"></div>
        <p>Loading...</p>
      </div>

      <div
        class="row pb-2"
        *ngFor="let order of orders"
        (click)="orderDescription(order)"
      >

      
        <div
          class="col p-0"
          *ngIf="
            order.subscriptionID &&
            (order.assignedAgent == phoneNumber ||
              order.assignedAgent == null)
          "
        >
          <div class="card small-card">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title">Lunch</h5>
                </div>
                <div class="col text-end">
                  <span class="badge bg-success"> Subscription </span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p>
                    Date:
                    {{ formatDate(order.orderForDate) }}
                  </p>
                </div>
              
              </div>
              <hr class="m-0 p-1" />

              <div class="row">
                <div class="col">
                  <p class="p-0 m-0 mb-1">Order Status:</p>
                </div>
                <div class="col">
                  <p class="p-0 m-0 mb-0" style="color: green">
                    {{
                      orderStatusSwitch(
                        order.orderStatus
                      )
                    }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p style="margin: 0">
                    <b>{{ order.userName }}</b>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <small style="margin-bottom: 5px">
                    <i
                      class="fas fa-map-marker-alt"
                      style="color: rgb(88, 133, 216)"
                    ></i>
                    &nbsp;&nbsp;{{ order.address.address }} </small
                  ><br />

                  <small
                    style="margin-bottom: 5px"
                    *ngIf="order.address.building"
                    class="detailContent"
                  >
                    {{ order.address.building }} </small
                  ><br />
                  <small
                    style="margin-bottom: 5px"
                    *ngIf="order.address.street"
                    class="detailContent"
                  >
                    {{ order.address.street }} </small
                  ><br />
                  <small
                    style="margin-bottom: 5px"
                    *ngIf="order.address.locality"
                    class="detailContent"
                  >
                    {{ order.address.locality }}
                  </small>

                  <div class="row mb-2">
                    <div class="col">
                      <small style="margin-bottom: 5px; margin-top: 5px">
                        <i
                          class="fas fa-phone fa-flip-horizontal"
                          style="color: green"
                        ></i
                        >&nbsp;&nbsp;
                        <a
                          style="color: blue"
                          [href]="'tel:' + order.address.deliveryNumber"
                        >
                          +91 {{ order.address.deliveryNumber }}</a
                        >
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            
              <div class="row">
                <div class="col-10">
                  <p class="p-0 m-0 mb-1" style="font-size: small">
                    Subscription ID: {{ order.subscriptionID }}
                  </p>
                </div>
                <div class="col-2">
                  <i
                    *ngIf="order.assignedAgent == null"
                    class="fas fa-lock-open"
                    style="color: green"
                  ></i>
                  <i
                    *ngIf="order.assignedAgent != null"
                    class="fas fa-lock"
                    style="color: red"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col p-0"
          *ngIf="
            order.assignedAgent == phoneNumber && order.orderID||
            (order.assignedAgent == null && order.orderID)
          "
        >
          <div class="card small-card">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title">Lunch</h5>
                </div>
                <div class="col text-end">
                  <h5 class="card-title">&#8377; {{ order.totalPrice }}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p>Date: {{ formatDate(order.orderForDate) }}</p>
                </div>
              </div>
              <hr class="m-0 p-1" />

              <div class="row">
                <div class="col">
                  <p class="p-0 m-0 mb-1">Order Status:</p>
                </div>
                <div class="col">
                  <p class="p-0 m-0 mb-0" style="color: green">
                    {{ orderStatusSwitch(order.orderStatus) }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p style="margin: 0">
                    <b>{{ order.userName }}</b>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <small style="margin-bottom: 5px">
                    <i
                      class="fas fa-map-marker-alt"
                      style="color: rgb(88, 133, 216)"
                    ></i>
                    &nbsp;&nbsp;{{ order.address.address }} </small
                  ><br />

                  <small
                    style="margin-bottom: 5px"
                    *ngIf="order.address.building"
                    class="detailContent"
                  >
                    {{ order.address.building }} </small
                  ><br />
                  <small
                    style="margin-bottom: 5px"
                    *ngIf="order.address.street"
                    class="detailContent"
                  >
                    {{ order.address.street }} </small
                  ><br />
                  <small
                    style="margin-bottom: 5px"
                    *ngIf="order.address.locality"
                    class="detailContent"
                  >
                    {{ order.address.locality }}
                  </small>

                  <div class="row mb-2">
                    <div class="col">
                      <small style="margin-bottom: 5px; margin-top: 5px">
                        <i
                          class="fas fa-phone fa-flip-horizontal"
                          style="color: green"
                        ></i
                        >&nbsp;&nbsp;
                        <a
                          style="color: blue"
                          [href]="'tel:' + order.address.deliveryNumber"
                        >
                          +91 {{ order.address.deliveryNumber }}</a
                        >
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="row">
                <div class="col">
                  <p class="p-0 m-0 mb-1">Payment method:</p>
                </div>
                <div class="col">
                  <p class="p-0 m-0 mb-1">
                    <span style="font-weight: 500; color: rgb(182, 47, 47)">{{
                      order.paymentMethod
                    }}</span>
                  </p>
                </div>
              </div> -->
              <div class="row">
                <div class="col-10">
                  <p class="p-0 m-0 mb-1" style="font-size: small">
                    Order ID: {{ order.orderID }}
                  </p>
                </div>
                <div class="col-2">
                  <i
                    *ngIf="order.assignedAgent == null"
                    class="fas fa-lock-open"
                    style="color: green"
                  ></i>
                  <i
                    *ngIf="order.assignedAgent != null"
                    class="fas fa-lock"
                    style="color: red"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- subscription orders -->
      <!-- <div
        class="row pb-2"
        *ngFor="let order of orders"
        (click)="goToSubscriptionDescription(order)"
      >
        <div
          class="col p-0"
          *ngIf="
            order.subscriptionID &&
            (order.assignedAgent == phoneNumber ||
              order.assignedAgent == null)
          "
        >
          <div class="card small-card">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title">Lunch</h5>
                </div>
                <div class="col text-end">
                  <span class="badge bg-success"> Subscription </span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p>
                    Date:
                    {{ formatDate(order.orderForDate) }}
                  </p>
                </div>
              
              </div>
              <hr class="m-0 p-1" />

              <div class="row">
                <div class="col">
                  <p class="p-0 m-0 mb-1">Order Status:</p>
                </div>
                <div class="col">
                  <p class="p-0 m-0 mb-0" style="color: green">
                    {{
                      orderStatusSwitch(
                        order.orderStatus
                      )
                    }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p style="margin: 0">
                    <b>{{ order.userName }}</b>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <small style="margin-bottom: 5px">
                    <i
                      class="fas fa-map-marker-alt"
                      style="color: rgb(88, 133, 216)"
                    ></i>
                    &nbsp;&nbsp;{{ order.address.address }} </small
                  ><br />

                  <small
                    style="margin-bottom: 5px"
                    *ngIf="order.address.building"
                    class="detailContent"
                  >
                    {{ order.address.building }} </small
                  ><br />
                  <small
                    style="margin-bottom: 5px"
                    *ngIf="order.address.street"
                    class="detailContent"
                  >
                    {{ order.address.street }} </small
                  ><br />
                  <small
                    style="margin-bottom: 5px"
                    *ngIf="order.address.locality"
                    class="detailContent"
                  >
                    {{ order.address.locality }}
                  </small>

                  <div class="row mb-2">
                    <div class="col">
                      <small style="margin-bottom: 5px; margin-top: 5px">
                        <i
                          class="fas fa-phone fa-flip-horizontal"
                          style="color: green"
                        ></i
                        >&nbsp;&nbsp;
                        <a
                          style="color: blue"
                          [href]="'tel:' + order.address.deliveryNumber"
                        >
                          +91 {{ order.address.deliveryNumber }}</a
                        >
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            
              <div class="row">
                <div class="col-10">
                  <p class="p-0 m-0 mb-1" style="font-size: small">
                    Subscription ID: {{ order.subscriptionID }}
                  </p>
                </div>
                <div class="col-2">
                  <i
                    *ngIf="order.assignedAgent == null"
                    class="fas fa-lock-open"
                    style="color: green"
                  ></i>
                  <i
                    *ngIf="order.assignedAgent != null"
                    class="fas fa-lock"
                    style="color: red"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div
        class="container"
        *ngIf="orders.length <= 0"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          height: 70vh;
        "
      >
        <div class="card noOrders">
          <div class="row p-3">
            <div class="col">
              <h3 class="noOderHeading" style="text-align: center">
                You don't have any deliveries yet.
              </h3>
              <hr class="m-0 p-0" />
            </div>
          </div>
          <div class="row p-2 pt-0">
            <div class="col">
              <p style="text-align: center; color: gray">
                Check again after some time.
              </p>
            </div>
          </div>
          <!-- <div class="row p-4 pt-0">
        <div class="col">
          <div class="div" style="text-align: center">
            <button
              type="button"
              class="btn btn-success noOrderButton"
              (click)="goBack()"
            >
              Go Back
            </button>
          </div>
        </div>
      </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
