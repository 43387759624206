import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private jwtHelper: JwtHelperService) {}

  isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    // Check if the token is expired
    return !this.jwtHelper.isTokenExpired(token);
  }
  
   // Method to retrieve the authentication token
   getToken(): string | null {
    // Retrieve the token from local storage or any other storage mechanism
    return localStorage.getItem('token');
  }

  // Other authentication methods can be implemented here
}
