import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { OrderService } from '../home/orders.service';

const BACKEND_URL_LUNCH_BELL = environment.apiUrlLunchBell;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  user: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private location: Location,
    private OrderService: OrderService
  ) {}

  ngOnInit(): void {
    let phoneNumber = localStorage.getItem('phoneNumber');
    this.fetchUserDetails(phoneNumber); // Replace 'examplePhone' with the actual phoneNumber
  }

  fetchUserDetails(phoneNumber: string | null): void {
    const url = `${BACKEND_URL_LUNCH_BELL}/user/${phoneNumber}`; // Adjust the URL as per your backend API
    this.http.get<any>(url).subscribe(
      (response) => {
        console.log('User details:', response);
        this.user = response.user;
      },
      (error) => {
        console.error('Error fetching user details:', error);
        // Handle error appropriately
      }
    );
  }

  logout(): void {
    // Clear local storage
    localStorage.clear();
    this.OrderService.orders = [];
    this.OrderService.nearestOrders = [];
    // Redirect to the login page
    this.router.navigate(['/login']);
  }
  goBack(): void {
    this.location.back();
  }
}
