import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { OrderDescriptionComponent } from './components/order-description/order-description.component';
import { AuthGuard } from './auth.guard';
import { SubscriptionDescriptionComponent } from './components/subscription-description/subscription-description.component';
import { DeliveredComponent } from './components/delivered/delivered.component';
import { ProfileComponent } from './components/profile/profile.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' }, // Default route redirects to /home
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard]  },
  { path: 'login', component: LoginComponent},
  { path: 'order-description', component: OrderDescriptionComponent, canActivate: [AuthGuard]},
  { path: 'subscription-description', component: SubscriptionDescriptionComponent, canActivate: [AuthGuard]},
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]},
  {path :'delivered', component: DeliveredComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
